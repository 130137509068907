import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./App.css";
import SpinnerNotLogged from "./components/Spinner/SpinnerNotLogged";
import { gapi } from "gapi-script";

//Components
//PAGES
const AdminLayout = lazy(() =>
  import("./components/Admin/AdminLayout/AdminLayout")
);
//PAGES
const AceptUser = lazy(() => import("./pages/ValidationUser/AceptUser"));
const AdminCategories = lazy(() =>
  import("./pages/Admin/AdminCategories/AdminCategories")
);
const AdminClub = lazy(() => import("./pages/Admin/AdminClub/AdminClub"));
const AdminCreate = lazy(() => import("./pages/Admin/AdminCreate/AdminCreate"));
const AdminEvaluations = lazy(() =>
  import("./pages/Admin/AdminEvaluations/AdminEvaluations")
);
const AdminTeams = lazy(() => import("./pages/Admin/AdminTeams/AdminTeams"));
const AdminUsers = lazy(() => import("./pages/Admin/AdminUsers/AdminUsers"));
const AuthLayoutRoute = lazy(() =>
  import("./components/AuthLayoutRoute/AuthLayoutRoute")
);
const Category = lazy(() => import("./pages/Category/Category"));
const Club = lazy(() => import("./pages/Club/Club"));
const CreateEvent = lazy(() => import("./pages/CreateEvent/CreateEvent"));
const CreateUser = lazy(() =>
  import("./pages/Admin/AdminUsers/components/CreateUser")
);
const CreatePlayerList = lazy(() =>
  import("./pages/CreateTeam/CreatePlayerList")
);
const CreateTeam = lazy(() => import("./pages/CreateTeam/CreateTeam"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Documentation = lazy(() => import("./pages/Documentation/Documentation"));
const Gps = lazy(() => import("./pages/Gps/Gps"));
const Login = lazy(() => import("./pages/Login/Login"));
const MakeEvaluation = lazy(() =>
  import("./pages/MakeEvaluation/MakeEvaluation")
);
const MakeEvaluationPlayer = lazy(() =>
  import("./pages/MakeEvaluation/MakeEvaluationPlayer")
);
//const Player = lazy(() => import( "./pages/Player/Player"));
const PlayerDocumentation = lazy(() =>
  import("./pages/PlayerDocumentation/PlayerDocumentation")
);
const PlayerProfile = lazy(() => import("./pages/Player/PlayerProfile"));
const PlayerSummary = lazy(() => import("./pages/PlayerSummary/PlayerSummary"));
const Recover = lazy(() => import("./pages/Recover/Recover"));
const Register = lazy(() => import("./pages/Register/Register"));
const RejectUser = lazy(() => import("./pages/ValidationUser/RejectUser"));
const RouteProtector = lazy(() =>
  import("./components/routeProtector/RouteProtector")
);
const Schedule1 = lazy(() => import("./pages/Schedule/Schedule1"));
const SideBar = lazy(() => import("./pages/layout/SideBar"));
const SkillsState = lazy(() => import("./context/SkillsContext/SkillsState"));
const Team = lazy(() => import("./pages/Team/Team"));
const TeamSummary = lazy(() => import("./pages/TeamSummary/TeamSummary"));
const Track = lazy(() => import("./pages/Gps/Components/Track"));
const User = lazy(() => import("./pages/User/User"));
const UserProfile = lazy(() => import("./pages/UserProfile/UserProfile"));
const TableViewStatics = lazy(() =>
  import("./pages/TeamManage/components/TableViewStatics")
);
const TeamManage = lazy(() => import("./pages/TeamManage/TeamManage"));
const TeamManageAdd = lazy(() => import("./pages/TeamManage/TeamManageAdd"));
const TeamManageEdit = lazy(() => import("./pages/TeamManage/TeamManageEdit"));
const VideoAnalisys = lazy(() => import("./pages/VideoAnalisis/VideoAnalisys"));
const Analisys = lazy(() => import("./pages/VideoAnalisis/Steps/Analisys"));
const VideoAnalysisResume = lazy(() =>
  import("./pages/VideoAnalisis/VideoAnalysisResume/VideoAnalysisResume")
);
const VideoAnalysisEdit = lazy(() =>
  import("./pages/VideoAnalisis/VideoAnalysisEdit/VideoAnalysisEdit")
);
const VideoanalysisSummary = lazy(() =>
  import("./pages/VideoAnalysisSummary/VideoanalysisSummary")
);
const AddNewAnalisys = lazy(() =>
  import("./pages/VideoAnalisis/AddNewAnalisys")
);
const GoalkeeperMain = lazy(() =>
  import("./pages/GoalkeeperExercise/GoalkeeperMain")
);
const GoalkeeperNewCreate = lazy(() =>
  import("./pages/GoalkeeperExercise/GoalkeeperNewCreate")
);
const TrainingReport = lazy(() =>
  import("./pages/GoalkeeperExercise/TrainingReport")
);
const TrainingPlayerReport = lazy(() =>
  import("./pages/GoalkeeperExercise/TrainingPlayerReport")
);
const NewPlayerSummary = lazy(() =>
  import("./pages/newPlayerSummary/NewPlayerSummary")
);
const WellnessTest = lazy(() => import("./pages/Wellness/Wellness"));
const SalesPotential = lazy(() =>
  import("./pages/SalesPotential/SalesPotential")
);
const ShowOportunityMap = lazy(() =>
  import("./pages/PlayerSummary/ShowOportunityMap")
);
const SuperAdmin = lazy(() => import("./pages/SuperAdmin/SuperAdmin"));
const SuperAdminClub = lazy(() => import("./pages/SuperAdmin/SuperAdminClub"));
const SuperAdminBilling = lazy(() =>
  import("./pages/SuperAdmin/SuperAdminBilling")
);
const SuperAdminPartners = lazy(() =>
  import("./pages/SuperAdmin/SuperAdminPartners")
);
const SuperAdminInvestors = lazy(() =>
  import("./pages/SuperAdmin/SuperAdminInvestors")
);
const IdealTeam = lazy(() => import("./pages/IdealTeam/IdealTeam"));
const NewPlayer = lazy(() => import("./pages/newPlayer/NewPlayer"));
const FreePlayers = lazy(() => import("./pages/FreePlayers/FreePlayers"));
const Scouting = lazy(() => import("./pages/Scouting/Scouting"));
const WinStrategy = lazy(() => import("./pages/WinStrategy/WinStrategy"));
const Contract = lazy(() => import("./pages/newPlayerSummary/tabs/Contract"));
const Tutors = lazy(() => import("./pages/newPlayerSummary/tabs/Tutors"));
const TransferTracker = lazy(() =>
  import("./pages/TransferTracker/TransferTracker")
);

const TranslationMain = lazy(() =>
  import("./pages/TranslationManagement/TranslationMain")
);

//CONTEXT
const CategoryState = lazy(() =>
  import("./context/CategoryContext/CategoryState")
);
const ClubState = lazy(() => import("./context/ClubContext/ClubState"));
const DocumentationState = lazy(() =>
  import("./context/DocumentationContext/DocumentationState")
);
const EvaluationState = lazy(() =>
  import("./context/EvaluationContext/EvaluationState")
);
const PlayerState = lazy(() => import("./context/PlayerContext/PlayerState"));
const ScheduleState = lazy(() =>
  import("./context/ScheduleContext/ScheduleState")
);
const TeamState = lazy(() => import("./context/TeamContext/TeamState"));
const UserState = lazy(() => import("./context/UserContext/UserState"));

const VideoAnalisysState = lazy(() =>
  import("./context/VideoAnalisysContext/VideoAnalisysState")
);
const TournamentState = lazy(() =>
  import("./context/TournamentContext/TournamentState")
);
const StatsState = lazy(() => import("./context/StatsContext/StatsState"));
const WellnessState = lazy(() =>
  import("./context/WellnessContext/WellnessState")
);
const LeagueState = lazy(() => import("./context/LeagueContext/LeagueState"));
// eslint-disable-next-line
const translate = lazy(() => import("./lang/lang"));
const TrainingEvaluation = lazy(() =>
  import("./pages/GoalkeeperExercise/TrainingEvaluation")
);
const ExerciseState = lazy(() =>
  import("./context/ExerciseContext/ExerciseState")
);
const SuscriptionsFan = lazy(() =>
  import("./pages/SuscriptionsFan/SuscriptionsFan")
);
const SuscriptionsLayout = lazy(() =>
  import("./pages/layout/SuscriptionsLayout")
);
const CategorySelected = lazy(() =>
  import("./pages/Category/CategorySelected")
);
const TournamentSelected = lazy(() =>
  import("./pages/Tournaments/TournamentSelected")
);
const MatchSelected = lazy(() => import("./pages/Tournaments/MatchSelected"));
const ContractForm = lazy(() =>
  import("./pages/Admin/AdminUsers/components/ContractForm")
);
const GuardianForm = lazy(() =>
  import("./pages/Admin/AdminUsers/components/GuardianForm")
);
const Tournaments = lazy(() => import("./pages/Tournaments/Tournaments"));
const Agreement = lazy(() => import("./pages/Agreement/Agreement"));
const SuccessZone = lazy(() =>
  import("./pages/SuccessZonePlayers/SuccessZonePlayers")
);
const HopeZone = lazy(() => import("./pages/HopeZonePlayers/HopeZonePlayers"));
const PromiseZone = lazy(() =>
  import("./pages/PromiseZonePlayers/PromiseZonePlayers")
);
const ProfessionalPlayers = lazy(() =>
  import("./pages/ProfessionalPlayers/ProfessionalPlayers")
);
const ResidencePlayers = lazy(() =>
  import("./pages/ResidencePlayers/ResidencePlayers")
);
const EmoHealth = lazy(() => import("./pages/EmoHealth/EmoHealth"));
const TeamBuilder = lazy(() => import("./pages/TeamBuilder/TeamBuilder"));
const Biotype = lazy(() => import("./pages/Biotype/Biotype"));

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function App() {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });

  return (
    <div className="App">
      <Router>
        <Suspense
          fallback={
            <div style={{ backgroundColor: "#163647" }}>
              <SpinnerNotLogged />
            </div>
          }
        >
          <Route exact path="/" render={() => <Redirect to="/login" />} />
          <Switch>
            <UserState>
              <DocumentationState>
                <ClubState>
                  <ScheduleState>
                    <CategoryState>
                      <TeamState>
                        <PlayerState>
                          <EvaluationState>
                            <VideoAnalisysState>
                              <TournamentState>
                                <SkillsState>
                                  <StatsState>
                                    <WellnessState>
                                      <LeagueState>
                                        <AuthLayoutRoute
                                          exact
                                          path="/register"
                                          component={Register}
                                        />
                                        <AuthLayoutRoute
                                          exact
                                          path="/login"
                                          component={Login}
                                        />
                                        <AuthLayoutRoute
                                          exact
                                          path="/recover"
                                          component={Recover}
                                        />
                                        <AuthLayoutRoute
                                          exact
                                          path="/user-acepted"
                                          component={AceptUser}
                                        />
                                        <AuthLayoutRoute
                                          exact
                                          path="/user-rejected"
                                          component={RejectUser}
                                        />

                                        <Route
                                          exact
                                          path="/translation-management"
                                        >
                                          <SideBar
                                            breadcrumVisible={false}
                                            breadcrumActive=""
                                            notUseparams="false"
                                          >
                                            <TranslationMain />
                                          </SideBar>
                                        </Route>

                                        <Route exact path="/suscriptionsfan">
                                          <SuscriptionsLayout>
                                            <SuscriptionsFan />
                                          </SuscriptionsLayout>
                                        </Route>
                                        <Route exact path="/agreement">
                                          <Agreement />
                                        </Route>

                                        <RouteProtector>
                                          <Route exact path="/">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="main panel"
                                              className="background-dashboard"
                                              notUseparams="true"
                                            >
                                              <Dashboard />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/main-panel">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="main panel"
                                              className="background-dashboard"
                                              notUseparams="true"
                                            >
                                              <Dashboard />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/player">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="players list"
                                              title="players list"
                                              notUseparams="true"
                                              className="background-dashboard"
                                            >
                                              <NewPlayer />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/gps">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="gps"
                                              notUseparams="true"
                                              className="background-dashboard"
                                            >
                                              <Gps />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/gps/track/:id">
                                            <SideBar
                                              breadcrumActive="track"
                                              breadcrumVisible={false}
                                              notUseparams="true"
                                            >
                                              <Track />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/category">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="category"
                                              className="background-dashboard"
                                              title="category"
                                              notUseparams="true"
                                            >
                                              <Category />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/category/:categoryId"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="category"
                                              className="background-dashboard"
                                              title="category"
                                              notUseparams="true"
                                            >
                                              <CategorySelected />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/make-evaluation">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="make an evaluation"
                                              className="background-dashboard"
                                              title="new evaluation"
                                              notUseparams="true"
                                            >
                                              <MakeEvaluation />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/player/summary/player_id/:id/user_id/:userId"
                                          >
                                            <SideBar
                                              breadcrumVisible={true}
                                              breadcrumActive="summary"
                                              title="player summary"
                                            >
                                              <PlayerSummary />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/player/summary/new/player_id/:id/user_id/:userId"
                                          >
                                            <SideBar
                                              breadcrumVisible={true}
                                              breadcrumActive="summary"
                                              title="player summary"
                                            >
                                              <NewPlayerSummary />
                                            </SideBar>
                                          </Route>

                                          <Route
                                            exact
                                            path="/player/contract/player_id/:id/user_id/:userId"
                                          >
                                            <SideBar
                                              breadcrumVisible={true}
                                              breadcrumActive="contract"
                                              // title="contract"
                                            >
                                              <Contract />
                                            </SideBar>
                                          </Route>
                                          {/* <SalesPotential /> */}

                                          <Route exact path="/tournament">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="tournament"
                                            >
                                              <Tournaments />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/tournament-selected/tournament/:tournamentId/:tournamentTeamId/category/:categoryId"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="tournament"
                                            >
                                              <TournamentSelected />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/tournament-selected/tournament/:tournamentId/:tournamentTeamId/category/:categoryId/match/:matchId"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="match"
                                            >
                                              <MatchSelected />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/superAdminClub">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="super admin clubs"
                                              title="super admin clubs"
                                            >
                                              <SuperAdminClub />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/superAdmin">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="super admin panel"
                                              title="super admin panel"
                                            >
                                              <SuperAdmin />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/superAdminBilling"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="super admin billing"
                                              title="super admin billing"
                                            >
                                              <SuperAdminBilling />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/superAdminPartners"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="super admin partners"
                                              title="super admin partners"
                                            >
                                              <SuperAdminPartners />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/superAdminInvestors"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="super admin investors"
                                              title="super admin investors"
                                            >
                                              <SuperAdminInvestors />
                                            </SideBar>
                                          </Route>

                                          <Route
                                            exact
                                            path="/player/tutors/player_id/:id/user_id/:userId"
                                          >
                                            <SideBar
                                              breadcrumVisible={true}
                                              breadcrumActive="tutors"
                                              // title="tutor"
                                            >
                                              <Tutors />
                                            </SideBar>
                                          </Route>

                                          <Route exact path="/scouting">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="scouting"
                                              className="background-dashboard"
                                              notUseparams="true"
                                              title="Scouting"
                                            >
                                              <Scouting />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/win-strategy">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="win-strategy"
                                              className="background-dashboard"
                                              notUseparams="true"
                                              title="Win Strategy®"
                                            >
                                              <WinStrategy />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/transfer-tracker">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="transfer-tracker"
                                              className="background-dashboard"
                                              notUseparams="true"
                                              title="Transfer Tracker®"
                                            >
                                              <TransferTracker />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/team-builder">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="Team Builder®"
                                              className="background-dashboard"
                                              notUseparams="true"
                                              title="Team Builder®"
                                            >
                                              <TeamBuilder />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/emo-health">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive=" "
                                              className="background-dashboard"
                                              notUseparams="true"
                                              title="Football Emo Health®"
                                            >
                                              <EmoHealth />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/success-zone">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive=" "
                                              className="background-dashboard"
                                              notUseparams="true"
                                              title="success zone"
                                            >
                                              <SuccessZone />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/hope-zone">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive=" "
                                              className="background-dashboard"
                                              notUseparams="true"
                                              title="hope zone"
                                            >
                                              <HopeZone />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/promise-zone">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive=" "
                                              className="background-dashboard"
                                              notUseparams="true"
                                              title="promise zone"
                                            >
                                              <PromiseZone />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/professional-players"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive=" "
                                              className="background-dashboard"
                                              notUseparams="true"
                                              title="professional players"
                                            >
                                              <ProfessionalPlayers />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/residence-players"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive=" "
                                              className="background-dashboard"
                                              notUseparams="true"
                                              title="residence players"
                                            >
                                              <ResidencePlayers />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/biotype">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive=" "
                                              className="background-dashboard"
                                              notUseparams="true"
                                              title="biotype"
                                            >
                                              <Biotype />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/player/documentation/player_id/:id/user_id/:userId"
                                          >
                                            <SideBar
                                              breadcrumVisible={true}
                                              breadcrumActive="documentation"
                                            >
                                              <PlayerDocumentation />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/player/profile/player_id/:id/user_id/:userId"
                                          >
                                            <SideBar
                                              breadcrumVisible={true}
                                              breadcrumActive="profile"
                                              //title="player profile"
                                            >
                                              <PlayerProfile />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/player/valoration/player_id/:id/user_id/:userId"
                                          >
                                            <SideBar
                                              breadcrumVisible={true}
                                              breadcrumActive="valoration"
                                            >
                                              <MakeEvaluationPlayer />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/player/om/player_id/:id/user_id/:userId"
                                          >
                                            <SideBar
                                              breadcrumVisible={true}
                                              breadcrumActive="oportunity_map"
                                            >
                                              <ShowOportunityMap />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/player/video_analysis/player_id/:id/user_id/:userId"
                                          >
                                            <SideBar
                                              breadcrumVisible={true}
                                              breadcrumActive="videoAnalysis"
                                            >
                                              <VideoanalysisSummary />
                                            </SideBar>
                                          </Route>

                                          <Route exact path="/salesPotential">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="sales potential"
                                            >
                                              <SalesPotential />
                                            </SideBar>
                                          </Route>
                                          {/* <Route exact path="/tournament">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="tournament"
                                            >
                                              <Tournaments />
                                            </SideBar>
                                          </Route> */}
                                          {/* <Route
                                            exact
                                            path="/tournament-selected/tournament/:tournamentId/:tournamentTeamId/category/:categoryId"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="tournament"
                                            >
                                              <TournamentSelected />
                                            </SideBar>
                                          </Route> */}
                                          {/* <Route
                                            exact
                                            path="/tournament-selected/tournament/:tournamentId/:tournamentTeamId/category/:categoryId/match/:matchId"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="match"
                                            >
                                              <MatchSelected />
                                            </SideBar>
                                          </Route> */}
                                          <Route exact path="/superAdminClub">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="admin clubs"
                                            >
                                              <SuperAdminClub />
                                            </SideBar>
                                          </Route>

                                          <Route exact path="/admin/users">
                                            <AdminLayout
                                              titlePage="users management"
                                              breadcrumActive="users"
                                            >
                                              <AdminUsers />
                                            </AdminLayout>
                                          </Route>
                                          <Route
                                            exact
                                            path="/admin/users/add-contract/player_id/:id"
                                          >
                                            <AdminLayout breadcrumActive="users">
                                              <ContractForm />
                                            </AdminLayout>
                                          </Route>
                                          <Route
                                            exact
                                            path="/admin/users/add-guardian/user_id/:id"
                                          >
                                            <AdminLayout breadcrumActive="users">
                                              <GuardianForm />
                                            </AdminLayout>
                                          </Route>

                                          <Route exact path="/admin/clubs">
                                            <AdminLayout
                                              titlePage="clubs management"
                                              breadcrumActive="clubs"
                                            >
                                              <AdminClub />
                                            </AdminLayout>
                                          </Route>
                                          <Route exact path="/admin/categories">
                                            <AdminLayout
                                              titlePage="categories management"
                                              breadcrumActive="categories"
                                            >
                                              <AdminCategories />
                                            </AdminLayout>
                                          </Route>
                                          <Route
                                            exact
                                            path="/admin/evaluations"
                                          >
                                            <AdminLayout
                                              titlePage="evaluations management"
                                              breadcrumActive="evaluations"
                                            >
                                              <AdminEvaluations />
                                            </AdminLayout>
                                          </Route>
                                          <Route exact path="/admin/teams">
                                            <AdminLayout
                                              titlePage="teams management"
                                              breadcrumActive="teams"
                                            >
                                              <AdminTeams />
                                            </AdminLayout>
                                          </Route>
                                          <Route exact path="/team">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="team"
                                              className="background-dashboard"
                                              title="my teams"
                                              notUseparams="true"
                                            >
                                              <Team />
                                            </SideBar>
                                          </Route>
                                          {/* <Route exact path="/team/summary/:id">
                                          <SideBar
                                            breadcrumVisible={false}
                                            breadcrumActive="team"
                                            className="background-dashboard"
                                            title="team summary"
                                            notUseparams="true"
                                          >
                                            <TeamSummary />
                                          </SideBar>
                                        </Route> */}
                                          {/* <Route exact path="/team/manage/:id">
                                          <SideBar
                                            breadcrumVisible={false}
                                            breadcrumActive="team manage"
                                            className="background-dashboard"
                                            title="manage team"
                                            notUseparams="true"
                                          >
                                            <TeamManage />
                                          </SideBar>
                                        </Route> */}
                                          <Route exact path="/idealTeam">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="ideal team"
                                            >
                                              <IdealTeam />
                                            </SideBar>
                                          </Route>
                                          {/* <Route exact path="/admin/clubs">
                                          <AdminLayout
                                            titlePage="clubs management"
                                            breadcrumActive="clubs"
                                          >
                                            <AdminClub />
                                          </AdminLayout>
                                        </Route> */}
                                          {/* <Route exact path="/admin/categories">
                                          <AdminLayout
                                            titlePage="categories management"
                                            breadcrumActive="categories"
                                          >
                                            <AdminCategories />
                                          </AdminLayout>
                                        </Route> */}
                                          <Route
                                            exact
                                            path="/admin/evaluations"
                                          >
                                            <AdminLayout
                                              titlePage="evaluations management"
                                              breadcrumActive="evaluations"
                                            >
                                              <AdminEvaluations />
                                            </AdminLayout>
                                          </Route>
                                          <Route exact path="/team/summary/:id">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="team"
                                              className="background-dashboard"
                                              title="team summary"
                                              notUseparams="true"
                                            >
                                              <TeamSummary />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/team/manage/:id">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="team manage"
                                              className="background-dashboard"
                                              title="manage team"
                                              notUseparams="true"
                                            >
                                              <TeamManage />
                                            </SideBar>
                                          </Route>

                                          <Route
                                            exact
                                            path="/team/manage/:id/tableviewstatics/:gameId"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="team manage"
                                              className="background-dashboard"
                                              title="stats players"
                                              notUseparams="true"
                                            >
                                              <TableViewStatics />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/team/manage/add/:id"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="team manage"
                                              className="background-dashboard"
                                              title="manage team: add"
                                              notUseparams="true"
                                            >
                                              <TeamManageAdd />
                                            </SideBar>
                                          </Route>

                                          <Route
                                            exact
                                            path="/team/manage/:id/edit/:gameId"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="team summary"
                                              className="background-dashboard"
                                              title="manage team: edit"
                                              notUseparams="true"
                                            >
                                              <TeamManageEdit />
                                            </SideBar>
                                          </Route>

                                          <Route exact path="/user">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="user"
                                              className="background-dashboard"
                                              title="my profile"
                                              notUseparams="true"
                                            >
                                              <User />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/club">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="Club"
                                              className="background-dashboard"
                                              title="my club"
                                              notUseparams="true"
                                            >
                                              <Club />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/team/create/1">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="team"
                                              className="background-dashboard"
                                              title="create team"
                                              notUseparams="true"
                                            >
                                              <CreateTeam />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/team/create/2">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="team"
                                              className="background-dashboard"
                                              title="create team"
                                              notUseparams="true"
                                            >
                                              <CreatePlayerList />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/documentation">
                                            <SideBar
                                              breadcrumActive="documentation"
                                              breadcrumVisible={false}
                                              className="background-dashboard"
                                              title="my documentation"
                                              notUseparams="true"
                                            >
                                              <Documentation />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/admin/users/profile/:id"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="user profile"
                                              title="user profile"
                                              className="background-dashboard"
                                              notUseparams="true"
                                            >
                                              <UserProfile />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/create/user">
                                            <AdminLayout
                                              breadcrumVisible={false}
                                              breadcrumActive="Create User"
                                              className="background-dashboard"
                                              title="create user"
                                            >
                                              <CreateUser />
                                            </AdminLayout>
                                          </Route>
                                          <Route exact path="/schedule">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="schedule"
                                              className="background-dashboard"
                                              title="schedule"
                                              notUseparams="true"
                                            >
                                              <Schedule1 />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/create/event">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="create event"
                                              className="background-dashboard"
                                              title="create event"
                                              notUseparams="true"
                                            >
                                              <CreateEvent />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/video-analisys">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="analysis"
                                              className="background-dashboard"
                                              title="analysis"
                                              notUseparams={true}
                                            >
                                              <VideoAnalisys />
                                            </SideBar>
                                          </Route>

                                          <Route
                                            exact
                                            path="/video-analysis/resume/:id"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="videoAnalysis"
                                              className="background-dashboard"
                                              title="analisys"
                                              notUseparams={true}
                                            >
                                              <VideoAnalysisResume />
                                            </SideBar>
                                          </Route>

                                          <Route
                                            exact
                                            path="/video-analysis/edit/:id"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="videoAnalysis"
                                              className="background-dashboard"
                                              title="analisys"
                                              notUseparams={true}
                                            >
                                              <VideoAnalysisEdit />
                                            </SideBar>
                                          </Route>

                                          <Route
                                            exact
                                            path="/videoanalisys-build"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="analisys"
                                              className="background-dashboard"
                                              title="analisys"
                                            >
                                              <AddNewAnalisys />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/admin/create">
                                            <AdminLayout
                                              titlePage="admin create"
                                              breadcrumActive="create"
                                            >
                                              <AdminCreate />
                                            </AdminLayout>
                                          </Route>
                                          <Route
                                            exact
                                            path="/create-video-analysis"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="analisys"
                                              className="background-dashboard"
                                              // title="analisys"
                                            >
                                              <Analisys />
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/create-video-analysis/:id"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="analisys"
                                              className="background-dashboard"
                                              // title="analisys"
                                            >
                                              <Analisys />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/training">
                                            <SideBar
                                              breadcrumVisible={false}
                                              notUseparams={true}
                                              breadcrumActive="Training"
                                              // className="background-dashboard"
                                              title="Training"
                                            >
                                              <ExerciseState>
                                                <GoalkeeperMain />
                                              </ExerciseState>
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/training-new">
                                            <SideBar
                                              breadcrumVisible={false}
                                              notUseparams={true}
                                              breadcrumActive="Training"
                                              // className="background-dashboard"
                                              title="New Training"
                                            >
                                              <ExerciseState>
                                                <GoalkeeperNewCreate />
                                              </ExerciseState>
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/training-evaluation/:id"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              notUseparams={true}
                                              breadcrumActive="Training"
                                              // className="background-dashboard"
                                              title="Training details"
                                            >
                                              <ExerciseState>
                                                <TrainingEvaluation />
                                              </ExerciseState>
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/training-report/:id"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              notUseparams={true}
                                              breadcrumActive="Training"
                                              //className="background-dashboard"
                                              title="Training report"
                                            >
                                              <ExerciseState>
                                                <TrainingReport />
                                              </ExerciseState>
                                            </SideBar>
                                          </Route>
                                          <Route
                                            exact
                                            path="/training-player-report/:id"
                                          >
                                            <SideBar
                                              breadcrumVisible={false}
                                              notUseparams={true}
                                              breadcrumActive="Training"
                                              //className="background-dashboard"
                                              // title="analisys"
                                            >
                                              <ExerciseState>
                                                <TrainingPlayerReport />
                                              </ExerciseState>
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/wellness-test">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="wellness test"
                                              className="background-dashboard"
                                              // title="wellness test"
                                              notUseparams={false}
                                            >
                                              <WellnessTest />
                                            </SideBar>
                                          </Route>
                                          <Route exact path="/free-players">
                                            <SideBar
                                              breadcrumVisible={false}
                                              breadcrumActive="free_players"
                                              className="background-dashboard"
                                              title="free_players"
                                              notUseparams={false}
                                            >
                                              <FreePlayers />
                                            </SideBar>
                                          </Route>
                                        </RouteProtector>
                                      </LeagueState>
                                    </WellnessState>
                                  </StatsState>
                                </SkillsState>
                              </TournamentState>
                            </VideoAnalisysState>
                          </EvaluationState>
                        </PlayerState>
                      </TeamState>
                    </CategoryState>
                  </ScheduleState>
                </ClubState>
              </DocumentationState>
            </UserState>
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}
export default App;
