
import "./Styles/Spinner.css"



const Spinner = () => {
  

  return (
    <div class="container">
      <div class="paper">
        <div style={{ textAlign: "center" }}>
          
            <span class="loader"></span>
            <p class="text">GLOOUDS</p>
            {/* <CircularProgress /> */}
          
        </div>
      </div>
    </div>
  );
};

export default Spinner;
